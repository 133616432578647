import { Component, inject, Signal } from '@angular/core';
import { InventoryService } from 'src/app/core/services/inventory.service';
import { InventoryTicket } from 'src/app/shared/models/ticket.model';

@Component({
  selector: 'app-my-tickets',
  templateUrl: './my-tickets.component.html',
  styleUrl: './my-tickets.component.css'
})
export class MyTicketsComponent {}
